// extracted by mini-css-extract-plugin
export var at = "dual-match-module--at--1adab";
export var bodyBase = "dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodyLarge = "dual-match-module--body-large--76b90 dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodyLargeBold = "dual-match-module--body-large-bold--d5942 dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodyRegular = "dual-match-module--body-regular--497fd dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodyRegularBold = "dual-match-module--body-regular-bold--bd069 dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodySmall = "dual-match-module--body-small--cf92d dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var bodySmallBold = "dual-match-module--body-small-bold--8356e dual-match-module--body-base--97ea9 dual-match-module--site-font--2c175";
export var borderTop = "dual-match-module--border-top--7f376";
export var box = "dual-match-module--box--857f7";
export var breakWordContainer = "dual-match-module--break-word-container--aeea4";
export var buttonIconBase = "dual-match-module--button-icon-base--6523c";
export var clickLink = "dual-match-module--click-link--28b65";
export var dropdownBase = "dual-match-module--dropdown-base--cef87";
export var dropdownSelectBase = "dual-match-module--dropdown-select-base--9da0d dual-match-module--text-input--f8e41";
export var flexCol = "dual-match-module--flex-col--8a70b";
export var formErrorMessage = "dual-match-module--form-error-message--51b67";
export var h3 = "dual-match-module--h3--332ed";
export var h4 = "dual-match-module--h4--b44ab";
export var hoverLink = "dual-match-module--hover-link--3afcd";
export var hoverRow = "dual-match-module--hover-row--0a9c5";
export var label = "dual-match-module--label--09f7e";
export var logo = "dual-match-module--logo--cb8f6";
export var membershipContainer = "dual-match-module--membership-container--641bd dual-match-module--flex-col--8a70b dual-match-module--primary-border--75700";
export var membershipHeader = "dual-match-module--membership-header--55ba0";
export var membershipHeading = "dual-match-module--membership-heading--f13ee";
export var membershipLabel = "dual-match-module--membership-label--4619a";
export var moreFiltersBorderClass = "dual-match-module--more-filters-border-class--3b03f";
export var opponentNameAndLogo = "dual-match-module--opponentNameAndLogo--a9d76";
export var pageBg = "dual-match-module--page-bg--3e362";
export var pointer = "dual-match-module--pointer--926aa";
export var primaryBorder = "dual-match-module--primary-border--75700";
export var shadowBoxLight = "dual-match-module--shadow-box-light--a8c11";
export var siteFont = "dual-match-module--site-font--2c175";
export var slideDownAndFade = "dual-match-module--slideDownAndFade--04211";
export var slideLeftAndFade = "dual-match-module--slideLeftAndFade--35c9c";
export var slideRightAndFade = "dual-match-module--slideRightAndFade--32391";
export var slideUpAndFade = "dual-match-module--slideUpAndFade--a5a13";
export var statusDecoration = "dual-match-module--status-decoration--380fa";
export var textInput = "dual-match-module--text-input--f8e41";
export var textInverted = "dual-match-module--text-inverted--c1d82";
export var textMediumDark = "dual-match-module--text-medium-dark--db724";
export var tooltipFont = "dual-match-module--tooltipFont--8c20c";
export var unstyledButton = "dual-match-module--unstyled-button--c660b";
export var vs = "dual-match-module--vs--26dc0";
import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import PlayerProfileHeader from 'src/components/player-profile-header/player-profile-header';
import { PlayerTabs } from 'src/components/player-tabs/player-tabs';
import { GET_PERSON_BY_EXTERNAL_ID } from 'src/components/players/players-queries';
import Spinner from 'src/components/spinner/spinner';
import SuccessNotification from 'src/components/success-notification/success-notification';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { GetPersonByExternalId } from 'src/graphql-types/GetPersonByExternalId';
import { getPersonFullName } from 'src/utils/helper';

import { BodyLargeBold } from '@clubspark-react/clubspark-react-tools';

export enum ActivityTypeEnum {
  StarterPoints = 'start points',
  TeamChallenge = 'team challenge event',
  ManualAdjustment = 'manual adjustment',
  JTT = 'jtt',
  RoundRobin = 'round robin event',
  JuniorCircuit = 'junior circuit',
  SingleWins = 'single win',
  DoubleWins = 'double win',
  YouthProgression = 'youth progression',
  Unknown = 'unknown',
}

interface Props {
  id?: string;
}

const PlayerProfile: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery<GetPersonByExternalId>(GET_PERSON_BY_EXTERNAL_ID, {
    client: meshGatewayClient,
    variables: { externalId: id },
  });

  const fullName = getPersonFullName(data?.personByExternalId);

  if (loading) {
    return <Spinner />;
  }

  if (!loading && (error || !data)) {
    return <BodyLargeBold>{t('no player profile found')}</BodyLargeBold>;
  }

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, active: true },
        ]}
      />
      <SuccessNotification atUrl={`/players/${id}`} variant={'success'} hideAfterMs={3000} />
      <PlayerProfileHeader id={id} data={data?.personByExternalId} loading={loading} />
      <PlayerTabs id={id} />
    </PageMaxWidth>
  );
};

export default PlayerProfile;

import React, { useCallback, useMemo, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import Modal from 'src/components/modal/modal';
import Panel from 'src/components/panel/panel';
import { EVALUATE_PROFILE, GET_ACTIVITY_EVENTS, GET_PLAYER_PROFILE } from 'src/components/players/players-queries';
import PointSummary from 'src/components/point-summary/point-summary';
import { ControlProps } from 'src/components/table-controls/table-controls';
import {
  ActivityEvents,
  ActivityEvents_activityEvents_items as ActivityEventsItems,
  ActivityEventsVariables,
} from 'src/graphql-types/ActivityEvents';
import { ActivityEventTypeEnum, PersonIDEnum } from 'src/graphql-types/globalUstaTypes';
import { UniqueIdType } from 'src/graphql-types/ita/globalITATypes';
import { PersonsData, PersonsData_personsById, PersonsDataVariables } from 'src/graphql-types/ita/PersonsData';
import CalendarSvg from 'src/images/svg-components/usta-calendar-icon.svg';
import InfoSvg from 'src/images/svg-components/usta-info.svg';
import { GET_ITA_PERSONS_BY_ID } from 'src/queries/ITA';
import theme from 'src/styles/theme';
import { StaffScope, useHasScope, useOrgLevel } from 'src/utils/auth';

import { BodyLargeBold, BodyRegular, BodyRegularBold, H3 } from '@clubspark-react/clubspark-react-tools';

import * as styles from './play-tracker.module.less';

export enum ActivityTypeEnum {
  StarterPoints = 'start points',
  TeamChallenge = 'team challenge event',
  ManualAdjustment = 'manual adjustment',
  JTT = 'jtt',
  RoundRobin = 'round robin event',
  SingleWins = 'single win',
  DoubleWins = 'double win',
  YouthProgression = 'youth progression',
  Unknown = 'unknown',
  JuniorCircuit = 'junior circuit',
  CoachAssignedLevel = 'coach assigned level',
}

const ActivityCountList = ({ data, type }) => {
  const { t } = useTranslation();
  let points;
  if (type === 'Red' || type === 'Orange' || type === 'GreenPlay') {
    points = 'totalPoints';
  } else if (type === 'GreenWin') {
    points = 'totalWinPoints';
  }

  return (
    <BodyRegular>
      <ul className={styles.list}>
        {data.length > 0 ? (
          data.map((a, index) => (
            <div key={a} className={`${index !== 0 && styles.borderTop} ${styles.row}`}>
              <li className={styles.activityItem}>{a.count + ' x ' + t(ActivityTypeEnum[a.type])}</li>
              <p className={styles.activityPoints}>
                {a[`${points}`]} {t('pts')}
              </p>
            </div>
          ))
        ) : (
          <div>
            <p className={styles.noPoints}>{t('no points')}</p>
          </div>
        )}
      </ul>
    </BodyRegular>
  );
};

const EventType = ({ member }) => {
  const { t } = useTranslation();
  const [showAdjustmentReason, setShowAdjustmentReason] = useState(false);

  const { firstName, lastName } = member?.adminUser || {};
  const name = firstName && lastName ? `${firstName} ${lastName}` : undefined;
  const byName = name ? `(${t('by')} ${name})` : '';

  return (
    <div className={styles.row}>
      {`${t(ActivityTypeEnum[member?.type]) || t('n/a')} ${byName || ''}`}
      {member?.originalData?.reason && (
        <Modal
          show={showAdjustmentReason}
          onVisibilityChange={setShowAdjustmentReason}
          title={`${t('manual adjustment')} ${byName}`}
          openButton={{
            hidden: true,
            custom: (
              <Button linkStyle={true} onClick={() => setShowAdjustmentReason(true)} size={'xs'} noWidth>
                <InfoSvg className={styles.padding} />
              </Button>
            ),
          }}
          cancelButton
          hideCloseButton
          buttonsLeft
        >
          {member?.originalData?.reason}
        </Modal>
      )}
    </div>
  );
};

const RED_ORANGE_TOTAL = 1000;
const GREEN_WIN_TOTAL = 1000;
const GREEN_TOTAL = 1500;

const isCoachType = (member: ActivityEventsItems) => member?.type === ActivityEventTypeEnum.CoachAssignedLevel;

const getCoach = (member: ActivityEventsItems, personsData?: PersonsData) => {
  return personsData?.personsById?.find((person) => person.externalId === member.originalData.coachId);
};

const formatCoachEventText = (coach: PersonsData_personsById, member: ActivityEventsItems, t: TFunction) => {
  const coachFullName = `${coach?.standardGivenName} ${coach?.standardFamilyName}`;
  const byName = `${t('by')} ${coachFullName}`;
  const { ballColour, level } = member.originalData || {};
  return `${t('coach assigned level')}, ${ballColour} ${t('level')} ${level} (${byName})`;
};

const formatAdminUserEventText = (member: ActivityEventsItems, t: TFunction) => {
  const name = `${member.adminUser?.firstName} ${member.adminUser?.lastName}`;
  const byName = `${t('by')} ${name}`;
  return `${t(ActivityTypeEnum[member.type]) || t('n/a')} (${byName})`;
};

const getEventValue = ({
  member,
  personsData,
  t,
}: {
  member: ActivityEventsItems;
  personsData?: PersonsData;
  t: TFunction;
}) => {
  if (isCoachType(member)) {
    const coach = getCoach(member, personsData);
    return coach ? formatCoachEventText(coach, member, t) : '';
  }

  if (member?.adminUser || member?.originalData?.reason) {
    return <EventType member={member} />;
  }

  if (member?.adminUser) {
    return formatAdminUserEventText(member, t);
  }

  return t(ActivityTypeEnum[member?.type]) || t('n/a');
};

export const PlayTracker = ({ id }) => {
  const { t } = useTranslation();

  const [pointsHistory, setPointsHistory] = useState<any>();
  const [totalItems, setTotalItems] = useState<number>(0);

  const adjustPointsAccess = useHasScope([StaffScope.PLAYTRACKER]);
  const { isNational } = useOrgLevel();

  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  const [sort, setSort] = useState({
    field: 'timestamp',
    direction: 'DESC',
  });

  const { data: profileData, refetch: refetchPlayerProfile } = useQuery(GET_PLAYER_PROFILE, {
    client: meshGatewayClient,
    variables: { id: { type: 'UAID', identifier: id } },
  });

  const [getPersons, { data: personsData }] = useLazyQuery<PersonsData, PersonsDataVariables>(GET_ITA_PERSONS_BY_ID, {
    client: meshGatewayClient,
  });

  const { data: pointsHistoryData, loading: loadingPointsHistory } = useQuery<ActivityEvents, ActivityEventsVariables>(
    GET_ACTIVITY_EVENTS,
    {
      client: meshGatewayClient,
      variables: {
        filter: { personIds: [{ type: PersonIDEnum.UAID, identifier: id }] },
        pageArgs: { limit: pagination.limit, skip: pagination.offset },
        sort: sort as any,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ activityEvents }) => {
        const coachIds = Array.from(
          new Set(
            activityEvents?.items?.reduce((res: Set<string>, item) => {
              if (item.type === ActivityEventTypeEnum.CoachAssignedLevel && item.originalData.coachId) {
                res.add(item.originalData.coachId as string);
              }
              return res;
            }, new Set<string>()),
          ),
        );
        if (!coachIds.length) {
          return;
        }
        getPersons({
          variables: {
            uniqueIds: coachIds,
            uniqueIdType: UniqueIdType.externalId,
          },
        });
      },
    },
  );

  const { activities, greenTotalPoints, greenWinPoints, points, redPoints, orangePoints } = useMemo(() => {
    const points = profileData?.activityProfile?.points;

    const red = points?.find((p: any) => p.ballColour === 'Red');
    const orange = points?.find((p: any) => p.ballColour === 'Orange');
    const green = points?.find((p: any) => p.ballColour === 'Green');

    const activityCount = profileData?.activityProfile?.counts;

    const greenPlayActivities = activityCount?.filter(
      (a) => a?.ballColour === 'Green' && a?.type !== 'SingleWins' && a?.type !== 'DoubleWins',
    );

    const greenWinActivities = activityCount?.filter((a) => a?.ballColour === 'Green' && a?.totalWinPoints > 0);

    const orangeActivities = activityCount?.filter((a) => a?.ballColour === 'Orange');
    const redActivities = activityCount?.filter((a) => a?.ballColour === 'Red');

    return {
      activities: {
        greenPlay: greenPlayActivities || [],
        greenWin: greenWinActivities || [],
        red: redActivities || [],
        orange: orangeActivities || [],
      },
      redPoints: red?.totalPoints || 0,
      orangePoints: orange?.totalPoints || 0,
      greenWinPoints: green?.totalWinPoints || 0,
      greenTotalPoints: green?.totalPoints || 0,
      points: {
        green: green,
        orange: orange,
        red: red,
      },
    };
  }, [profileData?.activityProfile?.counts, profileData?.activityProfile?.points]);

  const getSortOrder = useCallback((key) => {
    if (key === 'ASCENDING') return 'ASC';
    if (key === 'DESCENDING') return 'DESC';
    return 'ASC';
  }, []);

  const onControlChange = useCallback<ControlProps['onControlChange']>(
    (p) => {
      const { pagination, sorting } = p;
      pagination && setPagination(pagination);
      if (sorting) {
        setSort({
          field: sorting?.orderBy,
          direction: getSortOrder(sorting?.order),
        });
      }
      setPointsHistory(pointsHistoryData?.activityEvents?.items);
      setTotalItems(pointsHistoryData?.activityEvents?.totalItems as number);
    },
    [getSortOrder, pointsHistoryData],
  );

  const controls = useMemo<ControlProps>(
    () => ({
      onControlChange,
      totalItems,
    }),
    [totalItems, onControlChange],
  );

  const cols = useMemo<DataCols<ActivityEventsItems>>(() => {
    return [
      {
        key: 'timestamp',
        title: t('date'),
        getValue: (member) =>
          member.timestamp ? t('activity date', { timestamp: dayjs(member.timestamp) }) : t('n/a'),
      },
      {
        key: 'type',
        title: t('event'),
        getValue: (member) =>
          getEventValue({
            member,
            t,
            personsData,
          }),
      },
      {
        key: 'ageDivision',
        title: t('age division'),
        getValue: (member) => member?.ageCategoryCode ?? t('n/a'),
      },
      {
        key: 'location',
        title: t('location'),
        getValue: (member) => member?.location?.locationName ?? t('n/a'),
      },
      {
        key: 'ballColour',
        title: t('ball color'),
        getValue: (member) => member.ballColour || t('n/a'),
      },
      {
        key: 'playPoints',
        title: t('play points'),
        getValue: (member) => (isCoachType(member) ? '0' : member?.playPoints),
      },
      {
        key: 'totalWinPoints',
        title: t('win points'),
        getValue: (member) => (isCoachType(member) ? '0' : member?.totalWinPoints),
      },
      {
        key: 'totalPoints',
        title: t('total points'),
        getValue: (member) => (isCoachType(member) ? '0' : member?.totalPoints),
      },
    ];
  }, [t, personsData]);

  const [evaluateProfile, { loading: evaluatingProfile }] = useMutation(EVALUATE_PROFILE, {
    client: meshGatewayClient,
  });

  return (
    <Panel>
      <H3 spacing={{ margins: { sm: ['top', 'left'] } }}>{t('points summary')}</H3>
      <Grid container justifyContent={'space-between'}>
        <Grid item className={styles.summaryContainer}>
          <PointSummary
            title={t('red total points')}
            colors={[theme['error-color']]}
            points={redPoints}
            total={RED_ORANGE_TOTAL}
            gradientColors={[theme['error-color-light'], theme['error-color']]}
          >
            <Grid container direction="row" justifyContent="center">
              <Grid className={styles.red}>
                <BodyLargeBold>{`${points?.red?.playPoints || 0} ${t('red play pts')}`}</BodyLargeBold>
                <ViewDetails titleStyle={styles.red} title={`${t('red play points')}:`}>
                  <ActivityCountList data={activities?.red} type="Red" />
                </ViewDetails>
              </Grid>
            </Grid>
          </PointSummary>
        </Grid>

        <Grid item className={styles.summaryContainer}>
          <PointSummary
            title={t('orange total points')}
            colors={[theme['orange-color']]}
            points={orangePoints}
            total={RED_ORANGE_TOTAL}
            gradientColors={[theme['pending-color-alt-dark'], theme['pending-color-alt']]}
          >
            <Grid container direction="column" justifyContent="space-evenly" className={styles.orange}>
              <BodyRegularBold>
                {points?.orange?.playPoints || 0} {t('orange play pts')}
              </BodyRegularBold>
              <ViewDetails titleStyle={styles.orange} title={`${t('orange play points')}:`}>
                <ActivityCountList data={activities?.orange} type="Orange" />
              </ViewDetails>
            </Grid>
          </PointSummary>
        </Grid>

        <Grid item xs={12} md={6} className={styles.summaryContainer}>
          <PointSummary
            title={t('green win points')}
            colors={[theme['success-color']]}
            gradientColors={[theme['success-gradient']]}
            points={greenWinPoints}
            total={GREEN_WIN_TOTAL}
          >
            <Grid container direction="column" justifyContent="space-evenly" className={styles.green}>
              <BodyRegularBold>
                {points?.green?.totalWinPoints || 0} {t('win pts')}
              </BodyRegularBold>
              <ViewDetails titleStyle={styles.green} title={`${t('green win points')}:`}>
                <ActivityCountList data={activities?.greenWin} type="GreenWin" />
              </ViewDetails>
            </Grid>
          </PointSummary>
          <PointSummary
            title={t('green total points')}
            colors={[theme['success-color']]}
            points={greenTotalPoints}
            total={GREEN_TOTAL}
          >
            <Grid container direction="column" justifyContent="space-evenly" className={styles.green}>
              <BodyRegularBold>
                {points?.green?.playPoints || 0} {t('play pts')} + {points?.green?.totalWinPoints || 0} {t('win pts')}
              </BodyRegularBold>
              <ViewDetails titleStyle={styles.green} title={`${t('green total points')}:`}>
                <ActivityCountList data={activities?.greenPlay} type="GreenPlay" />
              </ViewDetails>
            </Grid>
          </PointSummary>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <H3 spacing={{ margins: { xs: 'left', md: 'bottom', lg: 'top' } }}>{t('points history')}</H3>

        <div className={styles.flex}>
          {isNational && (
            <Button
              spacing={{ margins: { md: ['vertical'], sm: 'right' } }}
              level="secondary"
              onClick={() =>
                evaluateProfile({
                  variables: {
                    filter: {
                      personIds: [{ type: 'UAID', identifier: id }],
                    },
                  },
                  onCompleted: () => {
                    refetchPlayerProfile();
                  },
                })
              }
              loading={evaluatingProfile}
            >
              {t('evaluate data')}
            </Button>
          )}

          {adjustPointsAccess && (
            <Button
              spacing={{ margins: { md: ['vertical'] } }}
              icon={'sm-add'}
              onClick={() => navigate(`/players/${id}/adjust-points`)}
            >
              {pointsHistory?.length > 0 ? t('adjust points') : t('add points')}
            </Button>
          )}
        </div>
      </Grid>
      <AdminTable
        spacing={{ margins: { lg: ['top', 'bottom'] } }}
        columns={cols}
        data={pointsHistoryData?.activityEvents?.items as any}
        controls={controls}
        loading={loadingPointsHistory}
        noDataComponent={
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CalendarSvg className={styles.icon} />
            <BodyLargeBold>{t('no points history')}</BodyLargeBold>
            <BodyRegular>{t('add some points')}</BodyRegular>
          </Grid>
        }
      />
    </Panel>
  );
};

interface ViewDetailsProps {
  title: string;
  titleStyle: string;
}

const ViewDetails: React.FC<ViewDetailsProps> = ({ titleStyle, title, children }) => {
  const [dialog, openDialog] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="button"
        onClick={() => openDialog(true)}
        spacing={{ margins: { xs: 'horizontal' } }}
        invertedLinkStyle={true}
      >
        {t('view details')}
      </Button>
      <CustomDialog
        title={title}
        titleStyle={titleStyle}
        open={dialog}
        content={children}
        onClose={() => openDialog(false)}
        hideX={true}
        actions={
          <Button level="tertiary" onClick={() => openDialog(false)}>
            {t('close')}
          </Button>
        }
      />
    </>
  );
};

import React from 'react';

import { Grid } from '@mui/material';

import * as styles from './spaced-grid.module.less';

const SpacedGrid: React.FC = ({ children }) => {
  return (
    <Grid container justifyContent="space-between" className={styles.spacing}>
      {children}
    </Grid>
  );
};

export default SpacedGrid;

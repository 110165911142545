import { useMemo } from 'react';

import { useOrgName } from 'src/apollo/local-state';
import { getClientConfig, getEnvConfig } from 'src/config/config';
import { usePlayerByExternalId } from 'src/hooks/data/suspensions';
import { StaffScope, useHasScope, useOrgHierarchy, userIsGlobalAdmin } from 'src/utils/auth';

import { PlayerProfileTab } from './types';

function isConfigFlagTrue(value: string) {
  return value === 'true';
}

const defaultDisabledTabs = [];

export const usePlayerProfileTabsVisibility = ({ id }: { id: string }) => {
  const { disabledProfileTabs = defaultDisabledTabs } = getClientConfig()?.playersModule ?? {};
  const suspensionsAccess = useHasScope([StaffScope.SUSPENSIONS]);
  const orgHierarchy = useOrgHierarchy();
  const isNationalStaff = orgHierarchy?.length === 1;
  const isGlobalAdmin = userIsGlobalAdmin();
  const orgName = useOrgName();
  const { data, loading } = usePlayerByExternalId(id);
  const sectionName = data?.personByExternalId?.section?.name;
  const districtName = data?.personByExternalId?.district?.name;

  const isPlayTrackerVisible = useMemo(() => {
    const personAge = data?.personByExternalId?.age;
    if (disabledProfileTabs.includes(PlayerProfileTab.PLAYTRACKER) || !personAge) return false;
    return personAge < 19;
  }, [disabledProfileTabs, data]);
  const isRankingsVisible = useMemo(() => {
    if (disabledProfileTabs.includes(PlayerProfileTab.RANKINGS)) return false;
    return isConfigFlagTrue(getEnvConfig().RANKINGS_REQUIRES_GLOBAL_ADMIN) ? isGlobalAdmin : true;
  }, [disabledProfileTabs, isGlobalAdmin]);
  const isTournamentsVisible = useMemo(() => {
    return !disabledProfileTabs.includes(PlayerProfileTab.TOURNAMENTS);
  }, [disabledProfileTabs]);
  const isDualMatchesVisible = useMemo(() => {
    return !disabledProfileTabs.includes(PlayerProfileTab.DUAL_MATCHES);
  }, [disabledProfileTabs]);
  const isSuspensionVisible = useMemo(() => {
    if (disabledProfileTabs.includes(PlayerProfileTab.SUSPENSIONS)) return false;
    const matchesDistrictOrSectionOrIsNationalStaff =
      orgName === districtName || orgName === sectionName || isNationalStaff;
    if (!suspensionsAccess || !matchesDistrictOrSectionOrIsNationalStaff) {
      return false;
    }
    return isConfigFlagTrue(getEnvConfig().SUSPENSIONS_REQUIRES_GLOBAL_ADMIN) ? isGlobalAdmin : true;
  }, [disabledProfileTabs, isGlobalAdmin, districtName, isNationalStaff, orgName, sectionName, suspensionsAccess]);

  return {
    isPlayTrackerVisible,
    isRankingsVisible,
    isTournamentsVisible,
    isDualMatchesVisible,
    isSuspensionVisible,
    isLoading: loading,
  };
};

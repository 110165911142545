// extracted by mini-css-extract-plugin
export var bodyBase = "player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodyLarge = "player-history-module--body-large--88a9f player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodyLargeBold = "player-history-module--body-large-bold--44f67 player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodyRegular = "player-history-module--body-regular--82efe player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodyRegularBold = "player-history-module--body-regular-bold--a06df player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodySmall = "player-history-module--body-small--c8322 player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var bodySmallBold = "player-history-module--body-small-bold--06305 player-history-module--body-base--77d3b player-history-module--site-font--10404";
export var borderTop = "player-history-module--border-top--56dcb";
export var breakWordContainer = "player-history-module--break-word-container--bbed4";
export var buttonIconBase = "player-history-module--button-icon-base--374e5";
export var clickLink = "player-history-module--click-link--700a6";
export var dropdownBase = "player-history-module--dropdown-base--97b0c";
export var dropdownSelectBase = "player-history-module--dropdown-select-base--7ef40 player-history-module--text-input--df198";
export var flexCol = "player-history-module--flex-col--1231e";
export var formErrorMessage = "player-history-module--form-error-message--503aa";
export var h3 = "player-history-module--h3--0b009";
export var h4 = "player-history-module--h4--1630f";
export var hoverLink = "player-history-module--hover-link--8bb5c";
export var hoverRow = "player-history-module--hover-row--78b14";
export var membershipContainer = "player-history-module--membership-container--ca492 player-history-module--flex-col--1231e player-history-module--primary-border--44d05";
export var membershipHeader = "player-history-module--membership-header--d8949";
export var membershipHeading = "player-history-module--membership-heading--82e05";
export var membershipLabel = "player-history-module--membership-label--8e7f0";
export var moreFiltersBorderClass = "player-history-module--more-filters-border-class--07d52";
export var pageBg = "player-history-module--page-bg--1b8bb";
export var pointer = "player-history-module--pointer--2ed9b";
export var primaryBorder = "player-history-module--primary-border--44d05";
export var shadowBoxLight = "player-history-module--shadow-box-light--77ec1";
export var siteFont = "player-history-module--site-font--10404";
export var slideDownAndFade = "player-history-module--slideDownAndFade--27343";
export var slideLeftAndFade = "player-history-module--slideLeftAndFade--1c5bd";
export var slideRightAndFade = "player-history-module--slideRightAndFade--a8a94";
export var slideUpAndFade = "player-history-module--slideUpAndFade--f2380";
export var statusDecoration = "player-history-module--status-decoration--ade6e";
export var tableHeader = "player-history-module--table-header--b5a3b";
export var textInput = "player-history-module--text-input--df198";
export var textInverted = "player-history-module--text-inverted--24766";
export var textMediumDark = "player-history-module--text-medium-dark--a9ed5";
export var tooltipFont = "player-history-module--tooltipFont--ae258";
export var unstyledButton = "player-history-module--unstyled-button--dc53d";
export var wtnLogo = "player-history-module--wtnLogo--358c0";
export var wtnRating = "player-history-module--wtnRating--5bb8e";
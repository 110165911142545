import { TD_GetRankList_td_rankList } from 'src/graphql-types/TD_GetRankList';
import { td_GenerateRankListInput } from 'src/graphql-types/TennisDataRankingTypes';

export const parseRankListInput = (rankList: TD_GetRankList_td_rankList) => {
  if (!rankList) {
    return {};
  }

  const generateRankListInput: td_GenerateRankListInput = {};

  const mappedProperties: (keyof TD_GetRankList_td_rankList)[] = [
    'listType',
    'playerType',
    'familyCategory',
    'playerLevel',
    'gender',
    'ageRestriction',
    'matchFormat',
    'matchFormatType',
    'region',
    'scheduleId',
  ];

  mappedProperties.forEach((property) => {
    const value = rankList[property];
    if (value !== null && value !== undefined) {
      generateRankListInput[property] = value;
    }
  });

  generateRankListInput['dateRange'] = {
    start: rankList.dateRange?.start,
    end: rankList.dateRange?.end,
  };

  return generateRankListInput;
};

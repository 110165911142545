import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import ItaPlayerProfile from 'src/components/player-profile-ita/ita-player-profile';
import PlayerProfileDefault from 'src/components/player-profile/player-profile';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import { getClientConfig } from 'src/config/config';

const profilePageComponent = {
  ITA: ItaPlayerProfile,
  DEFAULT: PlayerProfileDefault,
};

const PlayerProfilePage = () => {
  return (
    <Router basepath="/players">
      <PlayerProfileRoute path="/:id" />
    </Router>
  );
};

interface PlayerProfileRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileRoute: React.FC<PlayerProfileRouteProps> = ({ id }) => {
  const { profilePageVariant = '' } = getClientConfig()?.playersModule ?? {};
  const PlayerProfile = profilePageComponent[profilePageVariant] ?? profilePageComponent.DEFAULT;
  return (
    <Layout>
      <SEO title="Player Profile Suspensions" />
      <PrivateRoute>
        <PlayerProfile id={id} />
      </PrivateRoute>
    </Layout>
  );
};

export default PlayerProfilePage;

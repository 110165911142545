// extracted by mini-css-extract-plugin
export var arrowIcon = "player-ranking-module--arrow-icon--b75ec";
export var bodyBase = "player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodyLarge = "player-ranking-module--body-large--2f815 player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodyLargeBold = "player-ranking-module--body-large-bold--c1c36 player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodyRegular = "player-ranking-module--body-regular--f0764 player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodyRegularBold = "player-ranking-module--body-regular-bold--2d39f player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodySmall = "player-ranking-module--body-small--bd999 player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var bodySmallBold = "player-ranking-module--body-small-bold--6e0fc player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var borderSeparator = "player-ranking-module--border-separator--88de1";
export var borderTop = "player-ranking-module--border-top--a400a";
export var breakWordContainer = "player-ranking-module--break-word-container--74f53";
export var buttonIconBase = "player-ranking-module--button-icon-base--27e93";
export var clickLink = "player-ranking-module--click-link--77d17";
export var dropdownBase = "player-ranking-module--dropdown-base--f2021";
export var dropdownSelectBase = "player-ranking-module--dropdown-select-base--96a37 player-ranking-module--text-input--aea70";
export var flexCol = "player-ranking-module--flex-col--45abb";
export var formErrorMessage = "player-ranking-module--form-error-message--c7d64";
export var h3 = "player-ranking-module--h3--dc1b0";
export var h4 = "player-ranking-module--h4--55272";
export var hoverLink = "player-ranking-module--hover-link--d58ae";
export var hoverRow = "player-ranking-module--hover-row--f129d";
export var membershipContainer = "player-ranking-module--membership-container--01e45 player-ranking-module--flex-col--45abb player-ranking-module--primary-border--9fac8";
export var membershipHeader = "player-ranking-module--membership-header--34aca";
export var membershipHeading = "player-ranking-module--membership-heading--adee7";
export var membershipLabel = "player-ranking-module--membership-label--41328";
export var moreFiltersBorderClass = "player-ranking-module--more-filters-border-class--5f991";
export var pageBg = "player-ranking-module--page-bg--213db";
export var pointer = "player-ranking-module--pointer--67bcf";
export var points = "player-ranking-module--points--f229b";
export var pointsContainer = "player-ranking-module--points-container--03214";
export var primaryBorder = "player-ranking-module--primary-border--9fac8";
export var ranklistBaseInfo = "player-ranking-module--ranklist-base-info--d4bd7 player-ranking-module--primary-border--9fac8";
export var ranklistBaseItem = "player-ranking-module--ranklist-base-item--ad23c";
export var ranklistItemLabel = "player-ranking-module--ranklist-item-label--69c45";
export var ranklistItemValue = "player-ranking-module--ranklist-item-value--d227f";
export var shadowBoxLight = "player-ranking-module--shadow-box-light--80a64";
export var siteFont = "player-ranking-module--site-font--3b1d8";
export var slideDownAndFade = "player-ranking-module--slideDownAndFade--97a1d";
export var slideLeftAndFade = "player-ranking-module--slideLeftAndFade--b71ce";
export var slideRightAndFade = "player-ranking-module--slideRightAndFade--0de40";
export var slideUpAndFade = "player-ranking-module--slideUpAndFade--12ff4";
export var statusDecoration = "player-ranking-module--status-decoration--ccf6d";
export var tableHeader = "player-ranking-module--table-header--26924";
export var textInput = "player-ranking-module--text-input--aea70";
export var textInverted = "player-ranking-module--text-inverted--a344c";
export var textMediumDark = "player-ranking-module--text-medium-dark--f115a";
export var tickIcon = "player-ranking-module--tick-icon--f3c72";
export var tooltipFont = "player-ranking-module--tooltipFont--045e7";
export var totalPoints = "player-ranking-module--total-points--26bbf player-ranking-module--body-base--ffdc3 player-ranking-module--site-font--3b1d8";
export var unstyledButton = "player-ranking-module--unstyled-button--5157f";
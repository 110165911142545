import React, { useState } from 'react';

import { WTNScaleData } from '@clubspark/wtn-component-library';
import { Divider, Grid } from '@mui/material';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import Panel from 'src/components/panel/panel';
import Spinner from 'src/components/spinner/spinner';
import { Body, BodyRegular, BodySmall, H3 } from 'src/components/typography/typography';
import { getEnvConfig } from 'src/config/config';
import { capitalize } from 'src/utils/helper/rankings';
import { getConfidenceIcon, getConfidenceLevel } from 'src/utils/wtn';

import * as styles from './ita-player-profile-header.module.less';

interface Props {
  loading: boolean;
  imageSrc?: string;
  tennisID: string;
  topRowDetails?: { label?: string; value?: string | number }[];
  bottomRowDetails?: { label?: string; value?: string | number; isWtn?: boolean }[];
  isJunior?: boolean;
  tennisNumber?: number;
  confidence?: number;
  fullName?: string;
}

const ItaPlayerProfileHeader: React.FC<Props> = ({
  tennisID,
  loading,
  imageSrc,
  topRowDetails,
  bottomRowDetails,
  isJunior,
  tennisNumber,
  confidence,
  fullName,
}) => {
  const { t } = useTranslation();
  const [viewScaleModal, setViewScaleModal] = useState(false);

  const confidenceRatingLevel = tennisNumber ? getConfidenceLevel(confidence) : undefined;

  const initials = fullName
    ?.split(' ')
    ?.map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
    ?.join('');

  return (
    <>
      <Panel classNames={{ container: styles.headerContainer }}>
        {loading && <Spinner />}
        {!loading && (
          <>
            {imageSrc ? (
              <img src={imageSrc} className={styles.image} />
            ) : (
              <div className={styles.image}>
                <h2 className={styles.imageText}>{initials}</h2>
              </div>
            )}
            <Grid container item>
              <Grid container alignItems="center">
                <H3 noMargin>{fullName}</H3>
                {isJunior && <span className={styles.tag}>{capitalize(t('junior'))}</span>}
              </Grid>
              {topRowDetails && (
                <ul className={styles.detailsTop}>
                  {topRowDetails?.map((entry, index) => {
                    const value = entry.value ?? t('n/a');
                    return <li key={`${value}-${index}`}>{entry.label ? `${entry.label}: ${value}` : value}</li>;
                  })}
                </ul>
              )}
              {bottomRowDetails && (
                <>
                  <Divider className={styles.divider} />
                  <Grid container className={styles.detailsBottom}>
                    {bottomRowDetails?.map((entry, index) => {
                      return (
                        <div key={index} className={styles.bottomRow}>
                          <BodyRegular light>{entry.label}</BodyRegular>
                          <div className={cx({ [styles.statWtn]: entry.isWtn })}>
                            <BodySmall>{entry.isWtn && !tennisNumber ? t('n/a') : entry.value}</BodySmall>
                            {entry.isWtn && confidenceRatingLevel && (
                              <img className={styles.wtnRating} src={getConfidenceIcon(confidenceRatingLevel)} />
                            )}
                            {entry.isWtn && tennisNumber ? (
                              <Button
                                linkStyle
                                level="link"
                                noWidth
                                size="xs"
                                spacing={{ margins: { xxs: 'left' } }}
                                onClick={() => setViewScaleModal(true)}
                              >
                                {t('view scale')}
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </Panel>
      <CustomDialog
        title=""
        open={viewScaleModal}
        hideX
        overridePosition={{ width: '35%', margin: '0 auto' }}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Body size="xxl" bold spacing={{ margins: { xs: 'bottom' } }}>
              {t('world tennis number')}
            </Body>
            <WTNScaleData
              infoIcon={false}
              border={false}
              animDuration={4500}
              labelsDesktop
              tennisID={tennisID}
              width={360}
              endpointURL={getEnvConfig().ITF_GQL_URL}
            />
            <div className={styles.closeButtonContainer}>
              <Button level="tertiary" onClick={() => setViewScaleModal(false)}>
                {t('close')}
              </Button>
            </div>
          </div>
        }
        onClose={() => setViewScaleModal(false)}
      />
    </>
  );
};

export default ItaPlayerProfileHeader;

import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import { WTNScaleData } from '@clubspark/wtn-component-library';
import { Divider, Grid } from '@mui/material';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import Panel from 'src/components/panel/panel';
import { GET_PLAYER_PROFILE } from 'src/components/players/players-queries';
import SpacedGrid from 'src/components/spaced-grid/spaced-grid';
import Spinner from 'src/components/spinner/spinner';
import StatusLabel from 'src/components/status-label/status-label';
import { Body, BodyRegular, BodySmall, H3 } from 'src/components/typography/typography';
import { getEnvConfig } from 'src/config/config';
import { PersonStatusEnum } from 'src/graphql-types/globalUstaTypes';
import { getInitialsFromFullName, getPersonFullName } from 'src/utils/helper';
import { capitalize } from 'src/utils/helper/rankings';
import ROUTES from 'src/utils/routes';

import * as styles from './player-profile-header.module.less';

// Copied from @clubspark/wtn-component-library
export const roundDownWtn = (wtn: number): string => {
  return (Math.round((wtn + Number.EPSILON) * 100) / 100).toFixed(2).toString().slice(0, -1);
};

interface Props {
  loading: boolean;
  image?: string;
  data: any;
  id?: string;
}

const PlayerProfileHeader: React.FC<Props> = ({ id, loading, image, data: playerData }) => {
  const { t } = useTranslation();
  const { tennisId: tennisID } = playerData || {};
  const [viewScaleModal, setViewScaleModal] = useState(false);
  const isPlayerDuplicate = playerData?.status === PersonStatusEnum.Duplicate;
  const mergeWinnerExtension = playerData?.extensions?.find((ext) => ext.name === 'MergeWinnerPersonOtherIds');

  const { data: profileData } = useQuery(GET_PLAYER_PROFILE, {
    client: meshGatewayClient,
    variables: { id: { type: 'UAID', identifier: id } },
  });
  const ballColour = profileData?.activityProfile?.ballColour;
  const tennisNumber = playerData?.worldTennisNumbers?.find((i) => i.type === 'SINGLE')?.tennisNumber;

  const winnerProfileId = mergeWinnerExtension?.value?.[0]?.personId;
  const fullName = getPersonFullName(playerData);
  const initials = getInitialsFromFullName(fullName);

  const Tag = ({ tag }) => {
    return <div className={styles.tag}>{tag}</div>;
  };

  const getCompetitionLevel = () => {
    if (ballColour) return ballColour;
    if (playerData?.age > 10) return t('yellow');
    return null;
  };

  const details = {
    'usta id': playerData?.externalId || t('n/a'),
    section: playerData?.section?.name || t('n/a'),
    district: playerData?.district?.name || t('n/a'),
    state: playerData?.state || t('n/a'),
    'competition level': getCompetitionLevel() || t('n/a'),
    wtn: roundDownWtn(tennisNumber),
  };

  const getGender = (sex: 'MALE' | 'FEMALE') => {
    if (sex === 'MALE') return t('male');
    if (sex === 'FEMALE') return t('female');
    return t('n/a');
  };

  return (
    <>
      <Panel>
        {loading && <Spinner />}
        {!loading && (
          <Grid container justifyContent="space-between">
            <SpacedGrid>
              {image ? (
                <img src={image} className={styles.image} />
              ) : (
                <div className={styles.image}>
                  <h2 className={styles.imageText}>{initials}</h2>
                </div>
              )}

              <Grid container item xs={9} md={9} lg={10}>
                <Grid container justifyContent="space-between">
                  <Grid container item xs={6} md={10} lg={10}>
                    <Grid container direction="column">
                      <Grid container alignItems="center">
                        <H3 noMargin>{fullName}</H3>
                        {/* Temp solution */}
                        <div style={{ marginTop: 3 }}>
                          {playerData?.age < 19 && <Tag tag={capitalize(t('junior'))} />}
                        </div>
                        {isPlayerDuplicate && (
                          <StatusLabel variety="warning" spacing={{ margins: { xs: 'left' } }}>
                            {t('duplicate')}
                          </StatusLabel>
                        )}
                      </Grid>
                      {isPlayerDuplicate && (
                        <div>
                          <Button
                            level="link"
                            noWidth
                            spacing={{ margins: { xs: 'bottom' } }}
                            onClick={() => navigate(`${ROUTES.PLAYERS}/${winnerProfileId}?tab=suspensions`)}
                          >
                            {t('view survivor')}
                          </Button>
                        </div>
                      )}
                      <BodySmall>
                        {getGender(playerData?.sex)}, {playerData?.age || t('n/a')} {t('years abrv')}
                      </BodySmall>
                    </Grid>
                  </Grid>
                </Grid>

                <Divider className={styles.divider} />

                <Grid container>
                  {details &&
                    Object.entries(details).map(([key, value]) => {
                      return (
                        <div className={styles.stat} key={key}>
                          <BodyRegular light>{t(`${key}`)}</BodyRegular>
                          <div className={cx({ [styles.statWtn]: key === 'wtn' })}>
                            <BodySmall>{key === 'wtn' && !tennisNumber ? t('n/a') : value}</BodySmall>

                            {key === 'wtn' && tennisNumber ? (
                              <Button
                                linkStyle
                                level="link"
                                noWidth
                                size="xs"
                                spacing={{ margins: { xxs: 'left' } }}
                                onClick={() => setViewScaleModal(true)}
                              >
                                {t('view scale')}
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                </Grid>
              </Grid>
            </SpacedGrid>
          </Grid>
        )}
      </Panel>
      <CustomDialog
        title=""
        open={viewScaleModal}
        hideX
        overridePosition={{ width: '35%', margin: '0 auto' }}
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Body size="xxl" bold spacing={{ margins: { xs: 'bottom' } }}>
              World Tennis Number
            </Body>
            <WTNScaleData
              infoIcon={false}
              border={false}
              animDuration={4500}
              labelsDesktop
              tennisID={tennisID as string}
              width={360}
              endpointURL={getEnvConfig().ITF_GQL_URL}
            />
            <div className={styles.closeButtonContainer}>
              <Button level="tertiary" onClick={() => setViewScaleModal(false)}>
                {t('close')}
              </Button>
            </div>
          </div>
        }
        onClose={() => setViewScaleModal(false)}
      />
    </>
  );
};

export default PlayerProfileHeader;

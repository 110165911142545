// extracted by mini-css-extract-plugin
export var bodyBase = "player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodyLarge = "player-tabs-module--body-large--d6629 player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodyLargeBold = "player-tabs-module--body-large-bold--f1b84 player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodyRegular = "player-tabs-module--body-regular--7906d player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodyRegularBold = "player-tabs-module--body-regular-bold--f8b5c player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodySmall = "player-tabs-module--body-small--c2888 player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var bodySmallBold = "player-tabs-module--body-small-bold--ee408 player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var borderTop = "player-tabs-module--border-top--bcc09";
export var breakWordContainer = "player-tabs-module--break-word-container--9b1a1";
export var buttonIconBase = "player-tabs-module--button-icon-base--64084";
export var clickLink = "player-tabs-module--click-link--e6794";
export var dropdownBase = "player-tabs-module--dropdown-base--12c58";
export var dropdownSelectBase = "player-tabs-module--dropdown-select-base--ad0c8 player-tabs-module--text-input--25fc3";
export var flexCol = "player-tabs-module--flex-col--5659d";
export var formErrorMessage = "player-tabs-module--form-error-message--a05e3";
export var h3 = "player-tabs-module--h3--31766";
export var h4 = "player-tabs-module--h4--3e66b";
export var hoverLink = "player-tabs-module--hover-link--ab83f";
export var hoverRow = "player-tabs-module--hover-row--4d992";
export var indicator = "player-tabs-module--indicator--44989";
export var membershipContainer = "player-tabs-module--membership-container--65ad9 player-tabs-module--flex-col--5659d player-tabs-module--primary-border--3361b";
export var membershipHeader = "player-tabs-module--membership-header--73f54";
export var membershipHeading = "player-tabs-module--membership-heading--b7d7c";
export var membershipLabel = "player-tabs-module--membership-label--83131";
export var moreFiltersBorderClass = "player-tabs-module--more-filters-border-class--468f8";
export var pageBg = "player-tabs-module--page-bg--19612";
export var pointer = "player-tabs-module--pointer--63597";
export var primaryBorder = "player-tabs-module--primary-border--3361b";
export var selected = "player-tabs-module--selected--de103";
export var shadowBoxLight = "player-tabs-module--shadow-box-light--d475b";
export var siteFont = "player-tabs-module--site-font--6658e";
export var slideDownAndFade = "player-tabs-module--slideDownAndFade--83758";
export var slideLeftAndFade = "player-tabs-module--slideLeftAndFade--7e49c";
export var slideRightAndFade = "player-tabs-module--slideRightAndFade--69fbd";
export var slideUpAndFade = "player-tabs-module--slideUpAndFade--fbe4e";
export var statusDecoration = "player-tabs-module--status-decoration--e47b0";
export var tabPanel = "player-tabs-module--tabPanel--cc5a9";
export var tabStyle = "player-tabs-module--tabStyle--bad79 player-tabs-module--body-regular--7906d player-tabs-module--body-base--d48ba player-tabs-module--site-font--6658e";
export var tabsWrapper = "player-tabs-module--tabsWrapper--c0f22";
export var textInput = "player-tabs-module--text-input--25fc3";
export var textInverted = "player-tabs-module--text-inverted--68b39";
export var textMediumDark = "player-tabs-module--text-medium-dark--3082a";
export var tooltipFont = "player-tabs-module--tooltipFont--225b6";
export var unstyledButton = "player-tabs-module--unstyled-button--1bb87";
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Panel from 'src/components/panel/panel';
import { TD_GET_PLAYER_LIST_ITEMS, TD_GET_RANK_LIST } from 'src/components/rankings/queries';
import { FormatOptionsEnum } from 'src/components/rankings/utils/getRankListFilters';
import { MatchFormatTypeEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { MatchFormatEnum, RankListGenderEnum } from 'src/graphql-types/globalUstaTypes';
import { TD_GetPlayerListItems } from 'src/graphql-types/TD_GetPlayerListItems';
import { TD_GetRankList, TD_GetRankList_td_rankList_rankingItems_items } from 'src/graphql-types/TD_GetRankList';
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name';
import { StringParam, useQueryParams } from 'use-query-params';

import PlayerRankingFilters from './player-ranking-filters';
import PlayerRankingInfoHeader from './player-ranking-info-header';
import PlayerRankingPlayHistory from './player-ranking-play-history';

const getRankListFilters = (filterChange: RankListFilters) => {
  const { playerType, listType, matchFormat } = filterChange;

  const filters = { playerType, listType };

  if (matchFormat === FormatOptionsEnum.TEAM_DOUBLES) {
    filters['matchFormat'] = MatchFormatEnum.DOUBLES;
    filters['matchFormatType'] = MatchFormatTypeEnum.TEAM;
  } else if (matchFormat === FormatOptionsEnum.INDIVIDUAL_DOUBLES) {
    filters['matchFormat'] = MatchFormatEnum.DOUBLES;
    filters['matchFormatType'] = MatchFormatTypeEnum.INDIVIDUAL;
  } else if (matchFormat === FormatOptionsEnum.MIXED_IND_DOUBLES) {
    filters['matchFormat'] = MatchFormatEnum.DOUBLES;
    filters['matchFormatType'] = MatchFormatTypeEnum.INDIVIDUAL;
    filters['gender'] = RankListGenderEnum.X;
  } else {
    filters['matchFormat'] = matchFormat;
  }

  return filters;
};

export interface RankListFilters {
  playerType?: string | null;
  listType?: string | null;
  matchFormat?: string | null;
  rankingList?: string | null;
  divisionType?: string | null;
}

interface PlayerRankingsProps {
  id: string;
}

const PlayersRankings: React.FC<PlayerRankingsProps> = ({ id }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    rankListId: StringParam,
    tab: StringParam,
  });
  const [fetchedRedirectedList, setFetchedRedirectedList] = useState<boolean>(false);
  const [prevRankListFilters, setPrevRankListFilters] = useState<RankListFilters>({});
  const [rankListFilters, setRankListFilters] = useState<RankListFilters>({});
  const [selectedList, setSelectedList] = useState<TD_GetRankList_td_rankList_rankingItems_items | null>();

  // Query runs on page load if tab has been linked from a rank list directly
  const {
    data: rankList,
    loading: rankListLoading,
    error: rankListError,
  } = useQuery<TD_GetRankList>(TD_GET_RANK_LIST, {
    client: meshGatewayClient,
    variables: {
      id: query.rankListId,
      itemPageArgs: { limit: 1, skip: 0 },
      itemFilter: { participants: { itemId: id } },
    },
    skip: !query.rankListId || fetchedRedirectedList,
    onCompleted: (td_getRankList) => {
      setFetchedRedirectedList(true);
      const { playerType, listType, matchFormat } = td_getRankList?.td_rankList ?? {};
      const rankingList = generateRanklistName(td_getRankList?.td_rankList as any, t);
      setRankListFilters({
        playerType: playerType ?? PlayerTypeEnum.ADULT,
        listType,
        matchFormat,
        rankingList,
      });
      setSelectedList(td_getRankList?.td_rankList?.rankingItems?.items?.[0]);
    },
  });

  // On filter change, we want to fetch rank list candidates to populate the rankingList/runDates dropdowns
  const {
    data: rankListCandidates,
    refetch,
    loading: candidatesLoading,
    error: candidatesError,
  } = useQuery<TD_GetPlayerListItems>(TD_GET_PLAYER_LIST_ITEMS, {
    client: meshGatewayClient,
    variables: {
      filter: { ...getRankListFilters(rankListFilters), participants: { itemId: id } },
    },
    skip: !!query.rankListId && Object.keys(rankListFilters).length === 0,
  });

  const rankListFilterCandidates = useMemo(() => {
    return rankListCandidates?.td_rankListItems || (rankList?.td_rankList && [rankList?.td_rankList]) || [];
  }, [rankList?.td_rankList, rankListCandidates?.td_rankListItems]);

  useEffect(() => {
    if (!isEqual(rankListFilters, prevRankListFilters)) {
      refetch();
    }
    // Update previous filters after the refetch
    setPrevRankListFilters(rankListFilters);
  }, [refetch, rankListFilters, prevRankListFilters]);

  const handleSetRankListFilters = useCallback(
    (filterUpdate: RankListFilters) => {
      // Replace empty string values with null
      const updatedFilters = Object.fromEntries(
        Object.entries(filterUpdate).map(([key, value]) => [key, value === '' ? null : value]),
      );
      setRankListFilters(updatedFilters);
    },
    [setRankListFilters],
  );

  const handleSetSelectedList = useCallback((selected) => {
    setSelectedList(selected);
  }, []);

  return (
    <Panel title={t('rankings')}>
      <PlayerRankingFilters
        filters={rankListFilters}
        setFilters={handleSetRankListFilters}
        rankListCandidates={rankListFilterCandidates}
        setSelectedList={handleSetSelectedList}
      />
      <PlayerRankingInfoHeader
        rankList={selectedList}
        loading={rankListLoading || candidatesLoading}
        error={rankListError ?? candidatesError}
      />
      <PlayerRankingPlayHistory personId={id} rankList={selectedList} loading={rankListLoading || candidatesLoading} />
    </Panel>
  );
};

export default PlayersRankings;

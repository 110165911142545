import { TFunction } from 'react-i18next';
import { DivisionTypeEnum } from 'src/graphql-types/globalRankingTypes';

import { FormatOptionsEnum } from './getRankListFilters';

export const getAdultFormat = (divisionType: DivisionTypeEnum, t: TFunction) => {
  if (divisionType === DivisionTypeEnum.AGE) {
    return [
      { value: '', label: t('any format') },
      {
        value: FormatOptionsEnum.SINGLES,
        label: t('singles'),
      },
      {
        value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
        label: t('individual doubles'),
      },
      {
        value: FormatOptionsEnum.TEAM_DOUBLES,
        label: t('team doubles'),
      },
      {
        value: FormatOptionsEnum.MIXED_IND_DOUBLES,
        label: t('mixed individual doubles'),
      },
    ];
  }

  if (divisionType === DivisionTypeEnum.NTRP) {
    return [
      { value: '', label: t('any format') },
      {
        value: FormatOptionsEnum.SINGLES,
        label: t('singles'),
      },
      {
        value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
        label: t('individual doubles'),
      },
      {
        value: FormatOptionsEnum.MIXED_IND_DOUBLES,
        label: t('mixed individual doubles'),
      },
    ];
  }

  return [
    { value: '', label: t('any format') },
    {
      value: FormatOptionsEnum.SINGLES,
      label: t('singles'),
    },
    {
      value: FormatOptionsEnum.INDIVIDUAL_DOUBLES,
      label: t('individual doubles'),
    },
    {
      value: FormatOptionsEnum.TEAM_DOUBLES,
      label: t('team doubles'),
    },
    {
      value: FormatOptionsEnum.MIXED_IND_DOUBLES,
      label: t('mixed individual doubles'),
    },
  ];
};

import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DualMatchesPanel from 'src/components/dual-matches/dual-matches';
import EmptyState from 'src/components/empty-state/empty-state';
import { PlayTracker } from 'src/components/play-tracker/play-tracker';
import { PlayerHistory } from 'src/components/player-history/player-history';
import { PlayerRankings } from 'src/components/player-ranking/player-ranking';
import PlayersRankings from 'src/components/rankings/players-rankings/players-rankings';
import SuspensionPlayer from 'src/components/suspension-player/suspension-player';
import Tabs from 'src/components/tabs/tabs';
import { useV2Rankings } from 'src/utils/useV2Rankings';
import { StringParam, useQueryParams } from 'use-query-params';

import { Spinner, TabPanel } from '@clubspark-react/clubspark-react-tools';

import { usePlayerProfileTabsVisibility } from './hooks';
import * as styles from './player-tabs.module.less';

export const PlayerTabs = ({ id }) => {
  const {
    isLoading,
    isPlayTrackerVisible,
    isRankingsVisible,
    isSuspensionVisible,
    isTournamentsVisible,
    isDualMatchesVisible,
  } = usePlayerProfileTabsVisibility({ id });

  const getTabValue = useCallback(() => {
    const tabVisibility = {
      playTracker: isPlayTrackerVisible,
      rankings: isRankingsVisible,
      suspensions: isSuspensionVisible,
      tournaments: isTournamentsVisible,
      dualMatches: isDualMatchesVisible,
    };

    const visibleTabs = Object.entries(tabVisibility)
      .filter(([, value]) => Boolean(value))
      .map(([key], index) => [key, index]);
    return Object.fromEntries(visibleTabs);
  }, [isPlayTrackerVisible, isRankingsVisible, isSuspensionVisible, isTournamentsVisible, isDualMatchesVisible]);

  const tabValue = getTabValue();
  const [query, setQuery] = useQueryParams({
    tab: StringParam,
  });
  const { t } = useTranslation();
  const initialValue = query?.tab ? tabValue[query.tab] ?? 0 : 0;
  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getTabQuery = (value: number) => {
    const tabValue = getTabValue();
    switch (value) {
      case tabValue['playTracker']:
        return 'playTracker';
      case tabValue['rankings']:
        return 'rankings';
      case tabValue['suspensions']:
        return 'suspensions';
      case tabValue['tournaments']:
        return 'tournaments';
      case tabValue['dualMatches']:
        return 'dualMatches';
      default:
        return '';
    }
  };

  const getTabIndex = (tab: 'playTracker' | 'rankings' | 'suspensions' | 'tournaments' | 'dualMatches') => {
    return getTabValue()[tab];
  };

  const labelInfo = [
    { label: t('playtracker'), visible: isPlayTrackerVisible },
    { label: t('rankings'), visible: isRankingsVisible },
    { label: t('suspensions'), visible: isSuspensionVisible },
    { label: t('tournaments'), visible: isTournamentsVisible },
    { label: t('dualMatches'), visible: isDualMatchesVisible },
  ];
  const labels = labelInfo.filter(({ visible = true }) => visible).map((i) => i.label);

  useEffect(() => {
    initialValue && setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const tabEntries = Object.entries(getTabValue());
      const hasValue = tabEntries.some((entry) => entry.includes(value));
      const firstEntry = tabEntries?.[0];
      if (hasValue) {
        setQuery({ tab: getTabQuery(value) });
      } else if (firstEntry) {
        setQuery({ tab: firstEntry?.[0] });
      }
    }
  }, [value]);

  if (labels.length === 0) {
    return <EmptyState title={'No Play Tracker, Suspensions or Rankings data for this player'} icon="lg-empty-state" />;
  }

  if (isLoading) return <Spinner />;

  return (
    <>
      <Tabs value={value} labels={labels} handleChange={handleChange} />
      {isPlayTrackerVisible && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('playTracker')} className={styles.tabPanel}>
            <PlayTracker id={id} />
          </TabPanel>
        </Grid>
      )}
      {isRankingsVisible && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('rankings')} className={styles.tabPanel}>
            {useV2Rankings() ? <PlayersRankings id={id} /> : <PlayerRankings id={id} />}
          </TabPanel>
        </Grid>
      )}
      {isTournamentsVisible && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('tournaments')} className={styles.tabPanel}>
            <PlayerHistory id={id} />
          </TabPanel>
        </Grid>
      )}
      {isDualMatchesVisible && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('dualMatches')} className={styles.tabPanel}>
            <DualMatchesPanel id={id} />
          </TabPanel>
        </Grid>
      )}
      {isSuspensionVisible && (
        <Grid item xs={12}>
          <TabPanel value={value} index={getTabIndex('suspensions')} className={styles.tabPanel}>
            <SuspensionPlayer id={id} />
          </TabPanel>
        </Grid>
      )}
    </>
  );
};

import React, { useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import cx from 'classnames';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { Accordion, AccordionDetails, AccordionSummary } from 'src/components/accordion/accordion';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Icon from 'src/components/icon/icon';
import { Filters } from 'src/components/player-ranking/player-ranking';
import { GET_PERSONS_BY_EXTERNAL_ID } from 'src/components/players/players-queries';
import { Body } from 'src/components/typography/typography';
import { GetRankingsEvents_events_eventPlayers as EventPlayer } from 'src/graphql-types/GetRankingsEvents';
import { ListTypeEnum, MatchFormatEnum, PlayerTypeEnum } from 'src/graphql-types/globalRankingTypes';
import { PointsTypeEnum } from 'src/graphql-types/globalUstaTypes';
import { Ranklist } from 'src/graphql-types/Ranklist';
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name';
import { FormatOptionsEnum } from 'src/utils/helper/rankings';

import { SpacingProps } from '@clubspark-react/clubspark-react-tools/dist/hooks/spacing';

import * as styles from './player-ranking.module.less';

interface RankListOption {
  label: string;
  ranklistInfo: Ranklist;
  value: string;
}

export const getRunDates = (ranklists: any[], t: TFunction) => {
  if (!ranklists) {
    return [];
  }

  return ranklists?.map((ranklist) => ({
    value: new Date(ranklist?.createdAt)?.toISOString(),
    label: t('member date', {
      date: dayjs(ranklist?.createdAt).local(),
    }),
  }));
};

export const parseGender = (gender) => {
  if (gender === 'FEMALE') {
    return 'F';
  } else if (gender === 'MALE') {
    return 'M';
  } else {
    return gender;
  }
};

const isBonusList = (listType?: ListTypeEnum, playerType?: PlayerTypeEnum): boolean => {
  // Combined
  if (listType === ListTypeEnum.STANDING && playerType === PlayerTypeEnum.JUNIOR) {
    return true;
  }

  // Bonus points
  if (listType === ListTypeEnum.BONUS_POINTS) return true;

  return false;
};

export const getFormatOptions = (category: Filters['category'], listType: Filters['listType'], t: TFunction) => {
  switch (category) {
    case PlayerTypeEnum.ADULT:
      return [
        { value: FormatOptionsEnum.SINGLES, label: t('singles') },
        { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
      ];

    case PlayerTypeEnum.JUNIOR:
      switch (listType) {
        case ListTypeEnum.QUOTA:
          return [{ value: FormatOptionsEnum.COMBINED, label: t('combined') }];
        default:
          return [
            { value: FormatOptionsEnum.SINGLES, label: t('singles') },
            { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
            { value: FormatOptionsEnum.COMBINED, label: t('combined') },
          ];
      }

    case PlayerTypeEnum.WHEELCHAIR:
      return [
        { value: FormatOptionsEnum.SINGLES, label: t('singles') },
        { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
      ];

    default:
      return [
        { value: FormatOptionsEnum.SINGLES, label: t('singles') },
        { value: FormatOptionsEnum.DOUBLES, label: t('doubles') },
      ];
  }
};

export const getGenderLabels = (category: Filters['category']) => {
  if (category === PlayerTypeEnum.JUNIOR) {
    return {
      F: "Girls'",
      M: "Boys'",
    };
  } else {
    return {
      F: "Women's",
      M: "Men's",
    };
  }
};

export const getListTypeLabel = (listType: ListTypeEnum) => {
  switch (listType) {
    case ListTypeEnum.STANDING:
      return 'national standings list';
    case ListTypeEnum.SEEDING:
      return 'national seeding list';
    case ListTypeEnum.BONUS_POINTS:
      return 'bonus points list';
    default:
      return '';
  }
};

export const getListMatchFormatLabel = (matchFormat, listType, filters) => {
  switch (matchFormat) {
    case FormatOptionsEnum.SINGLES:
      return 'singles';
    case FormatOptionsEnum.DOUBLES:
      return 'doubles';
    default:
      if ((listType === ListTypeEnum.STANDING || listType === ListTypeEnum.BONUS_POINTS) && filters.category) {
        return 'combined';
      }
      return '';
  }
};

const dedupeRankLists = (rankLists: RankListOption[], preferredRankListId?: string) => {
  const dedupedRankLists: RankListOption[] = [];
  const labelMap: { [label: string]: RankListOption } = {};

  rankLists?.forEach((rankList) => {
    if ((preferredRankListId && rankList.value === preferredRankListId) || !labelMap[rankList.label]) {
      labelMap[rankList.label] = rankList;
    }
  });

  for (const label in labelMap) {
    dedupedRankLists.push(labelMap[label]);
  }

  return dedupedRankLists;
};

export const getListOptions = (ranklists: any[], t: TFunction, activeRankListId: string) => {
  const lists = ranklists?.map((ranklist) => {
    const {
      id,
      playerType,
      listType,
      ageRestriction,
      matchFormat,
      gender,
      matchFormatType,
      playerLevel,
      divisionType,
      familyCategory,
    } = ranklist ?? {};

    return {
      ranklistInfo: ranklist,
      value: id,
      label: generateRanklistName(
        {
          playerType,
          listType,
          ageRestriction,
          gender,
          matchFormat,
          matchFormatType,
          playerLevel,
          divisionType,
          familyCategory,
          genderModifier: '' as any,
        },
        t,
      ),
    };
  });

  return dedupeRankLists(lists, activeRankListId);
};

const RanklistBaseItem = ({ label, value, border = false }) => {
  return (
    <Grid
      container
      justifyContent="center"
      className={cx(styles.ranklistBaseItem, { [styles.borderSeparator]: border })}
      direction="column"
    >
      <p className={styles.ranklistItemLabel}>{label}</p>
      <p className={styles.ranklistItemValue}>{value}</p>
    </Grid>
  );
};

const getAdjustmentPoints = (adjustmentPoints: EventPlayer['adjustmentPoints'], format: MatchFormatEnum): number => {
  const singlesAdjustment = adjustmentPoints?.find((a) => a?.pointsType === PointsTypeEnum.SINGLES);
  const doublesAdjustment = adjustmentPoints?.find((a) => a?.pointsType === PointsTypeEnum.DOUBLES);
  const participationAdjustment = adjustmentPoints?.find((a) => a?.pointsType === PointsTypeEnum.PARTICIPATION);

  if (format === MatchFormatEnum.SINGLES) {
    return (singlesAdjustment?.pointsAdjustment ?? 0) + (participationAdjustment?.pointsAdjustment || 0);
  }

  if (format === MatchFormatEnum.DOUBLES) {
    return (doublesAdjustment?.pointsAdjustment || 0) + (participationAdjustment?.pointsAdjustment || 0);
  }

  if (format == MatchFormatEnum.COMBINED) {
    return (
      (doublesAdjustment?.pointsAdjustment || 0) + (participationAdjustment?.pointsAdjustment || 0) ||
      (singlesAdjustment?.pointsAdjustment ?? 0) + (participationAdjustment?.pointsAdjustment || 0) ||
      0
    );
  }

  return 0;
};

export const RanklistBaseInfo = (props) => {
  const { t } = useTranslation();
  const { rank, singlePoints, doublePoints, bonusPoints, totalPoints, isCombined, isAdult, format } = props;
  const isSingles = format === 'SINGLES';
  const isDoubles = format === 'DOUBLES';

  return (
    <Grid container className={styles.ranklistBaseInfo}>
      <Grid item xs>
        <RanklistBaseItem label={t('rank')} value={rank} border />
      </Grid>
      {(isCombined || isSingles) && (
        <Grid item xs>
          <RanklistBaseItem label={t('singles points')} value={singlePoints} border />
        </Grid>
      )}
      {(isCombined || isDoubles) && (
        <Grid item xs>
          <RanklistBaseItem label={t('doubles points')} value={doublePoints} border />
        </Grid>
      )}
      {isCombined && !isAdult && bonusPoints != null && (
        <Grid item xs>
          <RanklistBaseItem label={t('bonus points')} value={bonusPoints} border />
        </Grid>
      )}
      {!isAdult && (
        <Grid item xs>
          <RanklistBaseItem label={t('Total Points')} value={totalPoints} />
        </Grid>
      )}
    </Grid>
  );
};

interface ParsedPlayerResult {
  round: string;
  opponents: string;
  partner: string;
  description: string;
  won: string;
  score: string;
  points: string;
}

interface RanklistResultProps extends SpacingProps {
  uaid: string;
  ranklist: any;
  data: any;
  expanded: boolean;
  onChange: any;
}

export const RanklistResult: React.FC<RanklistResultProps> = ({
  ranklist,
  data = [],
  spacing,
  uaid,
  expanded,
  onChange,
}) => {
  const [playerIds, setPlayerIds] = useState([]);
  const { t } = useTranslation();
  const eventPlayer = data?.eventPlayers?.find((ep) => ep.playerId === uaid);
  const playerResults = eventPlayer?.playerResults;

  const { data: playerData } = useQuery(GET_PERSONS_BY_EXTERNAL_ID, {
    client: meshGatewayClient,
    variables: { externalIds: playerIds },
    skip: playerIds.length === 0,
  });

  const players = playerData?.personsByExternalId ?? [];

  const winningMatches = playerResults?.filter((result) => result.winnerId === uaid || result.winnerPartnerId === uaid);
  const lastWinningMatch = winningMatches?.[winningMatches?.length - 1];

  // If there are no playerResult items -  don't render anything
  if (!playerResults?.length) {
    return null;
  }

  useEffect(() => {
    if (playerResults) {
      const listOfIdLists =
        playerResults?.map((result) => [
          result.loserId,
          result.loserPartnerId,
          result.winnerId,
          result.winnerPartnerId,
        ]) || [];
      const ids = listOfIdLists.flat();
      const validIds = ids.filter((id) => id && id !== '0'); // Removes undefined/null and empty strings
      const uniqueValidIds = [...new Set(validIds)];
      setPlayerIds(uniqueValidIds as any);
    }
  }, [playerResults]);

  const parsedPlayerResults: ParsedPlayerResult[] = playerResults?.map((result) => {
    function getOpponentId() {
      if (result.loserId === uaid || result.loserPartnerId === uaid) return result.winnerId;
      return result.loserId;
    }

    function getPartnerId() {
      if (result.loserId === uaid) return result.loserPartnerId;
      if (result.loserPartnerId === uaid) return result.loserId;
      if (result.winnerPartnerId === uaid) return result.winnerId;
      if (result.winnerId === uaid) return result.winnerPartnerId;
    }

    function getOpponentPartnerId() {
      if (result.loserId === uaid || result.loserPartnerId === uaid) return result.winnerPartnerId;
      return result.loserPartnerId;
    }

    const opponentId = getOpponentId();
    const opponentPartnerId = getOpponentPartnerId();
    const partnerId = getPartnerId();
    const opponentWon = result.loserId === uaid || result.loserPartnerId === uaid;

    const opponent = players.find((opp) => opp.externalId === opponentId);
    const partner = players.find((opp) => opp.externalId === partnerId);
    const opponentPartner = players.find((opp) => opp.externalId === opponentPartnerId);
    const opponentName = `${opponent?.standardFamilyName?.toUpperCase()}, ${opponent?.standardGivenName}`;
    const partnerName = `${partner?.standardFamilyName?.toUpperCase()}, ${partner?.standardGivenName}`;
    const opponentPartnerName = `${opponentPartner?.standardFamilyName?.toUpperCase()}, ${
      opponentPartner?.standardGivenName
    }`;
    const opponentNames = `${opponentName} / ${opponentPartnerName}`;

    const isLastWinningMatch = lastWinningMatch?.round === result.round;

    return {
      id: result.id,
      round: result.round,
      opponents: opponentPartner ? opponentNames : opponentName,
      partner: partnerName,
      description: `${result.description}  |  ${t('member date', {
        date: dayjs(result?.tournamentStart).local(),
      })}`,
      won: opponentWon ? t('l') : t('w'),
      score: result?.score,
      bonusPoints: result?.bonusPointsWinValue,
      points: isLastWinningMatch ? eventPlayer?.totalPoints : '',
      positionOfPlay: result?.positionOfPlay,
    };
  });

  const totalPoints =
    (data?.singlesPoints || 0) + (data?.doublesPoints || 0) + (data?.bonusPoints || data?.participationPoints || 0);
  const bonusPoints = eventPlayer?.playerResults?.reduce((prev, curr) => {
    if (curr?.winnerId === uaid) return prev + +(curr.bonusPointsWinValue ?? 0);
    return prev;
  }, 0);

  const isDoublesList = ranklist?.matchFormat === FormatOptionsEnum.DOUBLES;

  const adjustmentPoints = getAdjustmentPoints(eventPlayer?.adjustmentPoints, ranklist?.matchFormat);
  const eventPoints = adjustmentPoints || (eventPlayer?.doublesPoints ?? eventPlayer?.singlesPoints);

  const tournamentEnd = data.tournament?.tournamentEnd;
  const tournamentEndLabel = tournamentEnd
    ? `- ${t('member date', {
        date: dayjs(tournamentEnd).local(),
      })}`
    : '';

  const flightName = ` | ${eventPlayer.flightName}`;
  const eventDescription = `${data.description}  |  ${t('member date', {
    date: dayjs(data.tournament?.tournamentStart).local(),
  })} ${tournamentEndLabel}`;
  const description = eventPlayer.flightName ? eventDescription.concat(flightName) : eventDescription;

  const getTournamentName = () => {
    const tournamentName = data?.tournament?.tournamentName ?? '';
    const level = data?.tournament?.level || data?.level;
    const levelLabel = level ? `${t('level')} ${level}` : '';

    return levelLabel ? `${levelLabel} - ${tournamentName}` : tournamentName;
  };

  const protectFromUndefined = (name: string) => (name?.includes('undefined') ? '' : name);

  const cols: DataCols<any> = [
    { key: 'round', title: t('round'), getValue: (m) => m.round || '-' },
    {
      key: 'partner',
      title: t('partner'),
      getValue: (m) => protectFromUndefined(m.partner) || '-',
      hidden: !isDoublesList,
    },
    {
      key: 'opponents',
      title: isDoublesList ? t('opponents') : t('opponent'),
      getValue: (m) => protectFromUndefined(m.opponents) || '-',
    },
    { key: 'win-lose', title: t('win lose'), getValue: (m) => m.won || '-' },
    {
      key: 'results',
      title: t('results'),
      getValue: (m) => m.score || '-',
    },
    {
      key: 'bonus',
      title: t('bonus'),
      getValue: (m) => {
        // Only display bonus points if match is won
        if (m.won === 'W') {
          return m.bonusPoints || '-';
        }

        return '-';
      },
      hidden: !isBonusList(ranklist?.listType, ranklist?.playerType),
    },
    {
      key: 'points',
      title: t('points'),
      getValue: (m) => m.points || '-',
    },
  ];

  if (eventPlayer.teamName) {
    cols.splice(1, 0, {
      key: 'teamName',
      title: t('teamName'),
      getValue: () => eventPlayer.teamName,
    });
    cols.splice(2, 0, {
      key: 'positionOfPlay',
      title: t('positionOfPlay'),
      getValue: (m) => m.positionOfPlay,
    });
  }

  const colsDatum = useMemo(() => cols.map((col, i) => ({ ...col, key: String(i) })), [cols]);

  return (
    <CustomGrid container spacing={spacing}>
      <Accordion expanded={expanded} handleChange={onChange}>
        <AccordionSummary>
          <Grid container className={styles.tableHeader}>
            <Grid container justifyContent="space-between">
              <div>
                <Grid>
                  <Body size="lg" bold spacing={{ margins: { xxs: 'left' } }}>
                    {getTournamentName()}
                  </Body>
                  <Body size="md" spacing={{ margins: { xxs: ['top', 'left'] } }}>
                    {description}
                  </Body>
                </Grid>
              </div>
              <Grid>
                <Grid container>
                  <CustomGrid
                    spacing={{ margins: { lg: 'left' } }}
                    className={styles.pointsContainer}
                    hide={ranklist?.playerType !== PlayerTypeEnum.JUNIOR}
                  >
                    <span className={styles.totalPoints}>{bonusPoints ?? 0}</span>
                    <p className={styles.points}>{t('bonus points').toUpperCase()}</p>
                  </CustomGrid>
                  <CustomGrid spacing={{ margins: { lg: 'left' } }} className={styles.pointsContainer}>
                    <span className={styles.totalPoints}>{eventPoints ?? 0}</span>
                    <p className={styles.points}>{t('event points').toUpperCase()}</p>
                  </CustomGrid>

                  <CustomGrid
                    spacing={{ margins: { lg: 'left' } }}
                    className={styles.pointsContainer}
                    hide={!data?.isVerified}
                  >
                    <Icon name="sm-tick" spacing={{ margins: { xxs: 'right' } }} />
                    <span className={styles.totalPoints}>{totalPoints}</span>
                    <p className={styles.points}>{t('list points').toUpperCase()}</p>
                  </CustomGrid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <AdminTable
            columns={colsDatum}
            data={sortMatches(parsedPlayerResults)}
            spacing={{ margins: { xs: 'top' } }}
            fixedWidth
          />
        </AccordionDetails>
      </Accordion>
    </CustomGrid>
  );
};

function sortMatches(matches: ParsedPlayerResult[]) {
  const order = [
    // Finals
    'Finals',
    'Final',
    'F',
    'PL-F',
    'C-F',
    'E-F',
    // Semifinals
    'Semifinals',
    'SF',
    'PL-S',
    'C-SF',
    'E-SF',
    'S',
    // Quarterfinals
    'Quarterfinals',
    'Q',
    'QF',
    'E-QF',
    'C-QF',
    'C-QF-Q',
    'Quarterfinals qualifier',
    // Round of 16
    'Round of 16',
    'R16',
    '16',
    'E-R16',
    'C-R16',
    'C-R16-Q',
    'Round of 16 qualifier',
    // Round of 32
    'Round of 32',
    'R32',
    '32',
    'E-R32',
    'C-R32',
    'C-R32-Q',
    'Round of 32 qualifier',
    // Round of 64
    'Round of 64',
    'R64',
    '64',
    'E-R64',
    'C-R64',
    'C-R64-Q',
    // Round of 128
    'Round of 128',
    'R128',
    '128',
    'E-R128',
    'C-R128',
  ];

  return matches.sort((a, b) => {
    const indexOfA = order.indexOf(a?.round);
    const backupIndexOfA = order.find((o) => o.toLowerCase().includes(a?.round?.toLowerCase()));
    const indexOfB = order.indexOf(b?.round);
    const backupIndexOfB = order.find((o) => o.toLowerCase().includes(b?.round?.toLowerCase()));

    const pickIndex = (index, backupIndex) => {
      return index < 0 ? backupIndex : index;
    };

    return pickIndex(indexOfA, backupIndexOfA) - pickIndex(indexOfB, backupIndexOfB);
  });
}

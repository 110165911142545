// extracted by mini-css-extract-plugin
export var bodyBase = "ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodyLarge = "ita-player-profile-header-module--body-large--c6440 ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodyLargeBold = "ita-player-profile-header-module--body-large-bold--58de8 ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodyRegular = "ita-player-profile-header-module--body-regular--b119f ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodyRegularBold = "ita-player-profile-header-module--body-regular-bold--1dde0 ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodySmall = "ita-player-profile-header-module--body-small--f95e4 ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var bodySmallBold = "ita-player-profile-header-module--body-small-bold--33ea2 ita-player-profile-header-module--body-base--92851 ita-player-profile-header-module--site-font--5d394";
export var borderTop = "ita-player-profile-header-module--border-top--44b9d";
export var bottomRow = "ita-player-profile-header-module--bottomRow--9ba7e";
export var breakWordContainer = "ita-player-profile-header-module--break-word-container--e1859";
export var buttonIconBase = "ita-player-profile-header-module--button-icon-base--761ac";
export var clickLink = "ita-player-profile-header-module--click-link--59b60";
export var closeButtonContainer = "ita-player-profile-header-module--close-button-container--27138";
export var detailsBottom = "ita-player-profile-header-module--detailsBottom--4b524";
export var detailsTop = "ita-player-profile-header-module--detailsTop--f5dab";
export var divider = "ita-player-profile-header-module--divider--e8244";
export var dropdownBase = "ita-player-profile-header-module--dropdown-base--c314c";
export var dropdownSelectBase = "ita-player-profile-header-module--dropdown-select-base--e6bf5 ita-player-profile-header-module--text-input--b8557";
export var duplicate = "ita-player-profile-header-module--duplicate--94dfd";
export var flexCol = "ita-player-profile-header-module--flex-col--0eaa2";
export var formErrorMessage = "ita-player-profile-header-module--form-error-message--e36f5";
export var h3 = "ita-player-profile-header-module--h3--e31c8";
export var h4 = "ita-player-profile-header-module--h4--9adf8";
export var headerContainer = "ita-player-profile-header-module--headerContainer--a2bca";
export var hoverLink = "ita-player-profile-header-module--hover-link--4d0c7";
export var hoverRow = "ita-player-profile-header-module--hover-row--09a3f";
export var image = "ita-player-profile-header-module--image--6f77d";
export var imageText = "ita-player-profile-header-module--image-text--410b6";
export var membershipContainer = "ita-player-profile-header-module--membership-container--ea225 ita-player-profile-header-module--flex-col--0eaa2 ita-player-profile-header-module--primary-border--a6447";
export var membershipHeader = "ita-player-profile-header-module--membership-header--65aa0";
export var membershipHeading = "ita-player-profile-header-module--membership-heading--45623";
export var membershipLabel = "ita-player-profile-header-module--membership-label--0d5b3";
export var moreFiltersBorderClass = "ita-player-profile-header-module--more-filters-border-class--cc059";
export var pageBg = "ita-player-profile-header-module--page-bg--8f8db";
export var pointer = "ita-player-profile-header-module--pointer--17857";
export var primaryBorder = "ita-player-profile-header-module--primary-border--a6447";
export var row = "ita-player-profile-header-module--row--07879";
export var shadowBoxLight = "ita-player-profile-header-module--shadow-box-light--d0c40";
export var siteFont = "ita-player-profile-header-module--site-font--5d394";
export var slideDownAndFade = "ita-player-profile-header-module--slideDownAndFade--22fb3";
export var slideLeftAndFade = "ita-player-profile-header-module--slideLeftAndFade--3a796";
export var slideRightAndFade = "ita-player-profile-header-module--slideRightAndFade--2be1a";
export var slideUpAndFade = "ita-player-profile-header-module--slideUpAndFade--f7212";
export var stat = "ita-player-profile-header-module--stat--f798c";
export var statWtn = "ita-player-profile-header-module--stat-wtn--4449f";
export var statusDecoration = "ita-player-profile-header-module--status-decoration--2ee33";
export var tag = "ita-player-profile-header-module--tag--3f8e1";
export var textInput = "ita-player-profile-header-module--text-input--b8557";
export var textInverted = "ita-player-profile-header-module--text-inverted--af8f8";
export var textMediumDark = "ita-player-profile-header-module--text-medium-dark--c76e7";
export var tooltipFont = "ita-player-profile-header-module--tooltipFont--93232";
export var unstyledButton = "ita-player-profile-header-module--unstyled-button--f72ea";
export var userProfile = "ita-player-profile-header-module--user-profile--6df6f";
export var wtnRating = "ita-player-profile-header-module--wtnRating--2f176";
// extracted by mini-css-extract-plugin
export var bodyBase = "player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodyLarge = "player-profile-header-module--body-large--23ebb player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodyLargeBold = "player-profile-header-module--body-large-bold--0c05f player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodyRegular = "player-profile-header-module--body-regular--9b6f6 player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodyRegularBold = "player-profile-header-module--body-regular-bold--697e9 player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodySmall = "player-profile-header-module--body-small--93cf5 player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var bodySmallBold = "player-profile-header-module--body-small-bold--acf69 player-profile-header-module--body-base--ad772 player-profile-header-module--site-font--c48a0";
export var borderTop = "player-profile-header-module--border-top--6dc27";
export var breakWordContainer = "player-profile-header-module--break-word-container--78ac4";
export var buttonIconBase = "player-profile-header-module--button-icon-base--a2be1";
export var clickLink = "player-profile-header-module--click-link--2ccbb";
export var closeButtonContainer = "player-profile-header-module--close-button-container--e8364";
export var divider = "player-profile-header-module--divider--2c480";
export var dropdownBase = "player-profile-header-module--dropdown-base--4bc22";
export var dropdownSelectBase = "player-profile-header-module--dropdown-select-base--d0348 player-profile-header-module--text-input--825c7";
export var duplicate = "player-profile-header-module--duplicate--db51e";
export var flexCol = "player-profile-header-module--flex-col--e4990";
export var formErrorMessage = "player-profile-header-module--form-error-message--50d21";
export var h3 = "player-profile-header-module--h3--55aae";
export var h4 = "player-profile-header-module--h4--57a30";
export var hoverLink = "player-profile-header-module--hover-link--f2660";
export var hoverRow = "player-profile-header-module--hover-row--bad0c";
export var image = "player-profile-header-module--image--feb1c";
export var imageText = "player-profile-header-module--image-text--0000b";
export var membershipContainer = "player-profile-header-module--membership-container--dc994 player-profile-header-module--flex-col--e4990 player-profile-header-module--primary-border--d8899";
export var membershipHeader = "player-profile-header-module--membership-header--e276f";
export var membershipHeading = "player-profile-header-module--membership-heading--e8b04";
export var membershipLabel = "player-profile-header-module--membership-label--ce95a";
export var moreFiltersBorderClass = "player-profile-header-module--more-filters-border-class--24968";
export var pageBg = "player-profile-header-module--page-bg--2de8b";
export var pointer = "player-profile-header-module--pointer--7c495";
export var primaryBorder = "player-profile-header-module--primary-border--d8899";
export var row = "player-profile-header-module--row--fa728";
export var shadowBoxLight = "player-profile-header-module--shadow-box-light--51282";
export var siteFont = "player-profile-header-module--site-font--c48a0";
export var slideDownAndFade = "player-profile-header-module--slideDownAndFade--596b0";
export var slideLeftAndFade = "player-profile-header-module--slideLeftAndFade--d6ec5";
export var slideRightAndFade = "player-profile-header-module--slideRightAndFade--b4473";
export var slideUpAndFade = "player-profile-header-module--slideUpAndFade--75a9d";
export var stat = "player-profile-header-module--stat--78094";
export var statWtn = "player-profile-header-module--stat-wtn--d6e43";
export var statusDecoration = "player-profile-header-module--status-decoration--b3068";
export var tag = "player-profile-header-module--tag--51d45";
export var textInput = "player-profile-header-module--text-input--825c7";
export var textInverted = "player-profile-header-module--text-inverted--13238";
export var textMediumDark = "player-profile-header-module--text-medium-dark--13e2c";
export var tooltipFont = "player-profile-header-module--tooltipFont--bb835";
export var unstyledButton = "player-profile-header-module--unstyled-button--ac3e6";
export var userProfile = "player-profile-header-module--user-profile--20ed3";
import React from 'react';

import cx from 'classnames';

import * as styles from './util-components.module.less';

export const NoWrap: React.FC<{ className?: string }> = ({ children, className }) => {
  return <span className={cx(styles.noWrap, className)}>{children}</span>;
};

export const AlignNumber: React.FC<{ val: React.ReactNode }> = ({ val }) => {
  return <span className={styles.alignNumber}>{val}</span>;
};

export const PageMaxWidth: React.FC = ({ children }) => {
  return <div className={styles.pageMax}>{children}</div>;
};

export const Caps: React.FC = ({ children }) => {
  return <span className={styles.capitalise}>{children}</span>;
};

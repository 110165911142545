import { RankListGenderEnum } from 'src/graphql-types/globalUstaTypes';
import {
  TD_GetRankListParticipants_td_rankListEligibleParticipants_division,
  TD_GetRankListParticipants_td_rankListEligibleParticipants_division_ageCategory,
} from 'src/graphql-types/TD_GetRankListParticipants';
import { td_EventTypeEnum } from 'src/graphql-types/TennisDataRankingTypes';

function getDivisionDefinition(division: TD_GetRankListParticipants_td_rankListEligibleParticipants_division): string {
  if (!division) return '';

  const { ageCategory, ratingCategory, eventType, gender, wheelchairRating, familyType } = division;

  let divisionDefinition = '';

  if (gender) {
    divisionDefinition += `${getGenderString(gender, ageCategory)}`;
  }

  if (ageCategory) {
    const { maximumAge, minimumAge } = ageCategory;
    divisionDefinition += ` ${getAgeRangeString(minimumAge, maximumAge)}`;
  }

  if (eventType) {
    divisionDefinition += ` ${getEventTypeString(eventType)}`;
  }

  if (ratingCategory) {
    const { value } = ratingCategory;
    divisionDefinition += ` ${getRatingString(value)}`;
  }

  if (wheelchairRating) {
    divisionDefinition += ` ${getWheelchairRatingString(wheelchairRating)}`;
  }

  return divisionDefinition;
}

function getRatingString(rating: number | null): string {
  if (rating) {
    return `(${rating.toFixed(1)})`;
  }
  return '';
}

function getWheelchairRatingString(rating: string | null): string {
  if (rating) {
    return `(${rating})`;
  }
  return '';
}

function getGenderString(
  gender: string,
  ageCategory?: TD_GetRankListParticipants_td_rankListEligibleParticipants_division_ageCategory | null,
): string {
  if (ageCategory?.maximumAge && gender === RankListGenderEnum.M) {
    return "Boy's";
  }
  if (ageCategory?.maximumAge && gender === RankListGenderEnum.F) {
    return "Girl's";
  }
  if (ageCategory?.minimumAge && gender === RankListGenderEnum.M) {
    return "Men's";
  }
  if (ageCategory?.minimumAge && gender === RankListGenderEnum.F) {
    return "Women's";
  }
  if (gender === RankListGenderEnum.X) {
    return 'Mixed';
  }
  if (gender === RankListGenderEnum.C) {
    return 'Coed';
  }

  return '';
}

function getAgeRangeString(minimumAge: number | null, maximumAge: number | null): string {
  if (minimumAge) {
    return `${minimumAge} & over`;
  }
  if (maximumAge) {
    return `${maximumAge} & under`;
  }

  return '';
}

function getEventTypeString(eventType: string): string {
  if (eventType === td_EventTypeEnum.SINGLES) {
    return 'singles';
  }
  if (eventType === td_EventTypeEnum.DOUBLES) {
    return 'doubles';
  }

  return '';
}

export default getDivisionDefinition;

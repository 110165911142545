import React from 'react';

import cx from 'classnames';
import {
  DualMatchesPaginated_dualMatchesPaginated_items_contextualInfo,
  DualMatchesPaginated_dualMatchesPaginated_items_teams,
  DualMatchesPaginated_dualMatchesPaginated_items_teams_logo,
} from 'src/graphql-types/ita/DualMatchesPaginated';
import { desk_HomeTeam } from 'src/graphql-types/ita/globalITATypes';

import * as styles from './dual-match.module.less';

export function findPlayerTeam(
  teams: DualMatchesPaginated_dualMatchesPaginated_items_teams[],
  teamId: string,
): DualMatchesPaginated_dualMatchesPaginated_items_teams | undefined {
  return teams?.find((team) => team.id.toUpperCase() === teamId.toUpperCase());
}

export function findOpponentTeam(
  teams: DualMatchesPaginated_dualMatchesPaginated_items_teams[],
  teamId: string,
): DualMatchesPaginated_dualMatchesPaginated_items_teams | undefined {
  return teams?.find((team) => team.id.toUpperCase() !== teamId.toUpperCase());
}

export function formatResult(
  teams: DualMatchesPaginated_dualMatchesPaginated_items_teams[],
  contextualInfo: DualMatchesPaginated_dualMatchesPaginated_items_contextualInfo | null,
  teamId: string,
): string | undefined {
  if (!teams) return;

  const playerTeam = findPlayerTeam(teams, teamId);
  const oppTeam = findOpponentTeam(teams, teamId);

  const winStatus = playerTeam?.didWin ? 'W' : oppTeam?.didWin ? 'L' : undefined;
  const score =
    contextualInfo?.result ||
    (playerTeam?.score && oppTeam?.score ? `${playerTeam?.score}-${oppTeam?.score}` : undefined);

  if (!score || !winStatus) return;

  return `${winStatus}, ${score}`;
}

export function matchLocationType(
  contextualInfo: DualMatchesPaginated_dualMatchesPaginated_items_contextualInfo | null,
): string {
  const homeTeam = contextualInfo?.homeTeam;

  if (!homeTeam) return '';

  switch (homeTeam) {
    case desk_HomeTeam.THIS_TEAM:
      return 'vs';
    case desk_HomeTeam.OPPONENT:
      return 'at';
    default:
      return '';
  }
}

export function getOpponentTeam(
  teams: DualMatchesPaginated_dualMatchesPaginated_items_teams[],
  contextualInfo: DualMatchesPaginated_dualMatchesPaginated_items_contextualInfo | null,
  teamId: string,
  translation,
): React.JSX.Element | string {
  const oppTeam = findOpponentTeam(teams, teamId);

  const opponentName = oppTeam?.name;
  const logoUrl = oppTeam?.logo?.url;
  const locationType = matchLocationType(contextualInfo);

  return opponentName ? (
    <div className={cx(styles.opponentNameAndLogo)}>
      <img
        src={logoUrl ? logoUrl : require('src/images/no-image.png').default}
        className={styles.logo}
        width="59px"
      ></img>
      {locationType ? (
        <span className={cx(styles[locationType], styles.box, styles.label)}>{translation(`${locationType}`)}</span>
      ) : null}
      {opponentName}
    </div>
  ) : (
    '-'
  );
}

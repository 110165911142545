import { useQuery } from '@apollo/client';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_PERSON_BY_EXTERNAL_ID } from 'src/components/players/players-queries';
import { GetPersonByExternalId } from 'src/graphql-types/GetPersonByExternalId';

export function usePlayerByExternalId(id: string) {
  const query = useQuery<GetPersonByExternalId>(GET_PERSON_BY_EXTERNAL_ID, {
    client: meshGatewayClient,
    variables: { externalId: id },
  });
  const player = query.data?.personByExternalId;
  return {
    ...query,
    fullName: `${player?.standardGivenName || ''} ${player?.standardFamilyName || ''}`,
  };
}
